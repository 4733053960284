import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const OrderDetails = ({ show, setShow, itemList }) => {
  console.log(itemList, "======");

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Items detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-md-12">
          <div className="row">
            {/* <div className="receipt-header">
                <div className="col-md-12">
                  <div className="receipt-left">
                    <img
                      className="img-responsive"
                      alt="iamgurdeeposahan"
                      src="https://bootdey.com/img/Content/avatar/avatar6.png"
                    />
                  </div>
                </div> 
                <div className="col-md-12">
                  <div className="receipt-right">
                    <h5>Company Name.</h5>
                    <p>
                      +1 3649-6589 <i className="fa fa-phone"></i>
                    </p>
                    <p>
                      company@gmail.com <i className="fa fa-envelope-o"></i>
                    </p>
                    <p>
                      USA <i className="fa fa-location-arrow"></i>
                    </p>
                  </div>
                </div>
              </div>*/}

            <div className="row">
              <div className="receipt-right">
                <h5>Name : {itemList?.user?.name}</h5>
                <p>
                  <b>Mobile :</b> {itemList?.user?.phoneNo && itemList?.user?.phoneNo}
                </p>
                <p>
                  <b>Email :</b> {itemList?.user?.email}
                </p>
              </div>
            </div>

            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S No.</th>
                    <th>Dish Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {itemList?.items?.map((item, index) => {
                    return (
                      <tr>
                        <td className="col-md-3">{index + 1}</td>
                        <td className="col-md-6">{item.dishName}</td>
                        <td className="col-md-6">{item.quantity}</td>
                        <td className="col-md-6">{item.price}</td>
                        <td className="col-md-3">
                          <i className="fa fa-inr"></i>
                          {item.totalPrice}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td></td>
                    <td className="text-right">
                      <p>
                        <strong>Total Amount: </strong>
                      </p>
                      {itemList?.discountedAmount > 0 ? 
                      <p>
                        <strong>Discounted: </strong>
                      </p>:""}
                      <p>
                        <strong>Taxes: </strong>
                      </p>
                    
                    </td>
                    <td>
                      <p>
                        <strong> 
                          <i className="fa fa-inr"></i>{itemList.cartTotal}/-
                        </strong>
                      </p>
                      {itemList?.discountedAmount > 0 ?    <p>
                        <strong>
                          <i className="fa fa-inr"></i> {itemList?.discountedAmount}
                        </strong>
                      </p>:""}
                   
                      <p>
                        <strong>
                          <i className="fa fa-inr"></i> {itemList?.taxAndCharges}/-
                        </strong>
                      </p>
                     
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="text-right">
                      <h4>
                        <strong>Total: </strong>
                      </h4>
                    </td>
                    <td className="text-left text-danger">
                      <h4>
                        <strong>
                          <i className="fa fa-inr"></i> {itemList?.finalAmount}/-
                        </strong>
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <div className="row">
              <div className="receipt-header receipt-header-mid receipt-footer">
                <div className="col-xs-8 col-sm-8 col-md-8 text-left">
                  <div className="receipt-right">
                    <p>
                      <b>Date :</b> 15 Aug 2016
                    </p>
                    <h5>Thanks for shopping.!</h5>
                  </div>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-4">
                  <div className="receipt-left">
                    <h1>Stamp</h1>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setShow(false)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderDetails;
