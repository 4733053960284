import { React, useEffect, useState } from 'react';
import { FaEdit, FaTrash } from "react-icons/fa";
import { BiDetail } from "react-icons/bi";

import { MDBDataTable, MDBInput } from 'mdbreact';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api, baseUrl } from '../url';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { selectCreater } from '../utils/custom-button';
import { Http } from '../http-dragon';
import Confirmation from '../components/confirmation';
import FullImage from '../components/full-image';
const OrderStatus = () => {
  const navigate = useNavigate()
  const [orderList, setOrderListList] = useState([])
  const [selectedStatus, setSelectedStatus] = useState("")

  const [loginData,setLoginData] = useState(null)
  const data = {
    columns: [
  
      {
        label: 'GST',
        field: 'gst',
        sort: 'asc',
        width: 150
      },
      {
        label: 'GST Proof',
        field: 'proofGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Fssai',
        field: 'fssai',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Fssai Proof',
        field: 'proofFSI',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Table',
        field: 'tablesQuantity',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Restaurant Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },

      {
        label: 'Edit',
        field: 'edit',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 150
      },

    ],
    rows: orderList
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"))
    setLoginData(data)
   
  }, [])




  const handleStatus = (e) => {
    if(e.target.value){
      setSelectedStatus(e.target.value)
      Http.loader(true)
  
        axios.post(baseUrl + api.getOrdersByOrderStatus, { orderStatus: e.target.value }).then(res => {
          setOrderListList(res.data?.restaurants)
          console.log(res.data);
          
          //  selectCreater(res.data.owners)
          Http.loader(false)
          // addImage(res.data?.restaurants)
          // addButton(res.data?.restaurants)
    
      }).catch (error=> {
        Http.loader(false)   
        setOrderListList([])
        
        toast.error(error.response.data.message)
      })
    }else{
      setSelectedStatus("")
    }
  
  }
  



  // const addButton = (array) => {
  //   array.forEach(element => {
  //     element.edit = <div to="/restaurant-edit" className="btn btn-primary pt-0 pb-1" onClick={() => editOwner(element._id)}><FaEdit /></div>
  //     element.delete = <div className={`btn btn-${element?.active ? "danger" : "success"} pt-0 pb-1`} onClick={() => activeOrDeActive(element._id, element?.active, element?.owner)}> {element?.active ? "De-Activate" : "Activate"}</div>
  //   });
  // }

 
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiDetail size='20' />

                        </span>
                      </span>
                      Restaurant List
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loginData?.role === "SUPER_ADMIN"? <div className="col-12 col-md-4 ">
            <label htmlFor="applicant"><b>Select Status</b></label>
            <select
                                          name="orderStatus"
                                          id="cars"
                                          className="form-select"
                                          value={selectedStatus}
                                          onChange={ handleStatus }
                                        >
                                          <option value="">
                                            Select Status...
                                          </option>
                                          <option value="pending">
                                            pending
                                          </option>
                                          <option value="confirm">
                                            confirm
                                          </option>
                                          <option value="preparing">
                                            preparing
                                          </option>
                                          <option value="served">served</option>
                                       
                                          <option value="prepared">
                                            prepared
                                          </option>
                                        
                                        </select>
          </div>:""}
          
          <section className='px-4 mt-5'>

            <div className="row">
              <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                <MDBDataTable
                  data={data}
                  responsive
                />

              </div>
            </div>
          </section>
        </div>
      </main>
     
    </>
  );
}
export default OrderStatus;