import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import Main from "./main";
import { useLoader } from "./hooks/Loader";
import { useNavigate } from "react-router-dom";
import { Routing } from "./utils/routing";
import { Http } from "./http-dragon";
import "react-toastify/dist/ReactToastify.css";
import { OrderContext } from "./loginData/RealTimeOrderProvider";

const App = () => {
  const loader = useLoader();
  const navigate = useNavigate();
  const {setRealTimeOrder} = useContext(OrderContext)
  Http.loader = loader.setLoader;
  Routing.navigate = navigate;
  useEffect(() => {
    let  token = localStorage.getItem("token") 
    
    // if(token){
    //     socketRegister(token,setRealTimeOrder)
    // }

  }, []);

  return <Main loader={loader.loader} />;
};

export default App;
