import { React, useEffect, useState } from 'react';
import { FaEdit, FaTrash } from "react-icons/fa";
import { BiDetail } from "react-icons/bi";

import { MDBDataTable, MDBInput } from 'mdbreact';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { api, baseUrl } from '../url';
import toast from 'react-hot-toast';
import { errorToast, successToast } from './logics/utils';
import { Http } from '../http-dragon';
import FullImage from '../components/full-image';
const Owner_List = () => {
  const navigate = useNavigate()
  const [ownerList, setOwnerList] = useState([])
  const [isShowImage, setIsShowImage] = useState(false)
  const [imageUrl, setImageUrl] = useState("")


  useEffect(() => {

    fetchOwner()
  }, [])

  const fetchOwner = async () => {
    Http.loader(true)
      axios.get(baseUrl + api.getOwners).then(res => {
        Http.loader(false)
        setOwnerList(res.data.owners)
        addButton(res.data.owners)
        addImage(res.data.owners)
      }). catch (error=> {
      Http.loader(false)
      setOwnerList([])
      toast.error(error.response.data.msg)
    })
  }
  const activeOrDeActive =async (_id,status) =>{
    Http.loader(true)
    try{
      console.log("aaaa");
      const  res = await  axios.post(baseUrl+api.activeOrDeActiveOwner,{ownerID:_id,status:!status})
      successToast(res)
      Http.loader(false)
      fetchOwner()
    }catch(error){
      Http.loader(false)
      errorToast(error)
    }
  }
  const editOwner = (data) => {
    let stateData = {
      name: data.name, pan: data.pan, idProof: data.idProof, phoneno: data.phoneno, email: data.email, adhaar: data.adhaar,id:data._id
    }
    navigate("/owner-details", { state: stateData })
  }
  const addButton = (array) => {
    array.forEach(element => {
      element.edit = <div className="btn btn-primary pt-0 pb-1" onClick={() => editOwner(element)}><FaEdit /></div>
      element.delete =  <div className={`btn btn-${element?.active ? "danger" :"success"} pt-0 pb-1`} onClick={()=>activeOrDeActive(element._id ,element?.active)}> {element?.active ? "De-Activate" :"Activate"}</div>
    });
  }
  const showImage = (url)=>{
       setImageUrl(url)
       setIsShowImage(true)
  }
  const addImage = (array)=>{
    array.forEach(element => {
      element.proof = <div className="crPointer" onClick={() => showImage(element?.idProof)}><img height={30} width={30} src={element?.idProof} alt='no img'/></div>
    });
  }
  const data = {
    columns: [

      {
        label: 'Mobile',
        field: 'phoneno',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Owner Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },

      {
        label: 'Pan',
        field: 'pan',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Proof',
        field: 'proof',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Edit',
        field: 'edit',
        sort: 'asc',
        width: 70
      },
      {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 70
      },

    ],
    rows: ownerList
  };
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiDetail size='20' />

                        </span>
                      </span>
                      Owner List
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className='px-4 mt-5'>

            <div className="row">
              <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                <MDBDataTable
                  data={data}
                  responsive
                />

              </div>
            </div>
          </section>
        </div>
      </main>
    <FullImage show={isShowImage} setShow={setIsShowImage} data={imageUrl}/>
    </>
  );
}
export default Owner_List;