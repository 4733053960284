
 import {io} from 'socket.io-client'
 const socket = io("https://bhukkadadminbackend.bhukkads.in",{autoConnect:false})

export const connectFromSocket = (fetchOrders,id)=>{
  let token = localStorage.getItem("token")
  socket.connect()
  socket.on("connect",()=>{
    
    console.log("========",socket.id);
    socket.emit("register",token)
  })
  socket.on("ORDER-RECIEVED",(order)=>{
    console.log("orderrsss", order);
    // fetchOrders(id)
    // setRealTimeOrder(order)
  })
}
