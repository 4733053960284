import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { FaListAlt } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Select from "react-select";
import { requestPost } from "../set-apis";
import { Http } from "../http-dragon";
import { api } from "../url";
import { selectCreater } from "../utils/custom-button";
import { dateEasyFormat, dateWithTime } from "../utils/date-formater";
import { OrderContext } from "../loginData/RealTimeOrderProvider";
import CurrentOrder from "../components/current-order";
import { toast } from "react-toastify";
import Confirmation from "../components/confirmation";
import OrderDetails from "../components/order-details"
import Swal from 'sweetalert2'
import { connectFromSocket } from "./sockets/socket";
const Orders = () => {
  const { realTimeOrder, setRealTimeOrder } = useContext(OrderContext);
  const [orderDetailPopup, setOrderDetailPopup] = useState(false);

  const [name, setName] = useState("");
  const [restaurantsList, setRestaurantsList] = useState([]);
  const [restaurantValue, setRestaurantValue] = useState("");
  const [orderType, setOrderType] = useState("DINING");
  const [allOrderList, setAllOrderList] = useState([]);
  const [takeAwayOrderList, setTakeAwayOrderList] = useState([]);
  const [preOrderList, setPreOrderList] = useState([]);
  const [inCommingOrder, setInCommingOrder] = useState({});
  const [isInCommingOrder, setIsInCommingOrder] = useState(false);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState("");
  const [confirmationMsg, setConfirmationMsg] = useState(
    "Are you sure? This action can't revert!!"
  );
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [orderBody, setOrderBody] = useState(null);
  const [loginData, setLoginData] = useState(null); 
  const [itemList,setItemList] = useState({user:null,restaurant:null,items:null})

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"));
    setLoginData(data);
    givePermit(data);
  }, [name]);

  useEffect(() => {

  }, []);

  const givePermit = (data) => {
    if (data?.role === "SUPER_ADMIN") {
      if (name) {
        const clear = setTimeout(() => {
          fetchRestaurant();
        }, 500);
        return () => clearTimeout(clear);
      }
    } else if (data?.role === "OWNER") {
      selectCreater(data?.restaurant);
      setRestaurantsList(data?.restaurant);
    } else if (data?.role === "MANAGER") {
      fetchOrders(data?.restaurant[0]?._id);
    }
  };

  const fetchRestaurant = async () => {
    Http.loader(true);
    const res = await requestPost(api.getRestaurantsByName, { search: name });
    Http.loader(false);
    console.log("res.data", res.data);

    if (!res.data) {
      setRestaurantsList([]);
    } else {
      selectCreater(res.data?.data);
      setRestaurantsList(res.data?.data);
    }
  };

  const fetchOrders = async (restaurantID) => {
    const res = await requestPost(api.findAllOrders, {
      restaurantID,
    });
    if (!res.data) {
      setAllOrderList([]);
    } else {
      setAllOrderList(res.data?.data);

    }
  };

  const dropdownChangeHandler = (e) => {

    setSelectedRestaurantId(e.value);
    setRestaurantValue(e);
    fetchOrders(e.value);
    connectFromSocket(fetchOrders,e.value)
  };
  const handleStatus = (e, orderID) => {
    let orderStatus = e.target.value;
    setOrderBody({ orderStatus, orderID });
    if (orderStatus === "completed" || orderStatus === "cancelled") {
      setIsConfirmation(true);
    } else {
      hanleOrderStatus({ orderStatus, orderID });
    }
  };

  const hanleOrderStatus = async (body = null) => {
    const res = await requestPost(
      api.changeOrderStatus,
      body ? body : orderBody
    );
    if (!res.data) {
    } else {
      fetchOrders(selectedRestaurantId);
    }
  };

  const handleItemList = (item)=>{
    setOrderDetailPopup(true)
    setItemList(item)
  }


  const payAmount =async(orderId) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "Amount paid by cash.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Paid"
    }).then((result) => {
      if (result.isConfirmed) {
        requestPost(api.changePaymentStatus,{orderId}).then((res)=>{
          if(res.data){
            fetchOrders(selectedRestaurantId)
          }else{
            toast.error(res.error.response.data.message)
          }
        })
       
      }
    });

  }

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaListAlt size="20" />
                        </span>
                      </span>
                      Orders
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="mt-5 restaurant-edit">
            {loginData?.role === "SUPER_ADMIN" ||
            loginData?.role === "OWNER" ? (
              <div className="row justify-content-center">
                <div className="col-12 col-md-4 ">
                  <label htmlFor="applicant">
                    <b>Search Restaurant</b>
                  </label>
                  <Select
                    onInputChange={(e) => setName(e)}
                    inputValue={name}
                    options={restaurantsList}
                    value={restaurantValue}
                    onChange={dropdownChangeHandler}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="my-5">
                <section className="upload-recm ">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                        <div className="shadowc p-1  bg-hero">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>User Name</th>
                                <th>Final Amount</th>
                                <th>Cart Total</th>
                                <th>Date</th>
                                <th>Day</th>
                                <th>Start & End time</th>
                                <th>Table No.</th>
                                <th>Party Size</th>
                                <th>Status</th>
                                <th>Orders Type</th>
                                <th>Handle Payment</th>
                                <th>Items</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allOrderList.length > 0 ? (
                                allOrderList.map((item) => {
                                  return (
                                    <tr>
                                      <td>{item.user.name} </td>
                                      <td> {item.finalAmount}</td>
                                      <td>{item.cartTotal}</td>
                                      <td>{dateEasyFormat(item?.preOrderDate)}</td>
                                      <td>{item?.slot?.day}</td>
                                      <td className="text-danger">{item?.slot?.startTime} - 
                                         
                                          {item?.slot?.endTime}</td>  
                                          <td>{item?.tableNo >0 ? <button type="button" className="btn btn-success">{item.tableNo}</button>:"-"} </td>
                                      <td>{item?.partySize ? item?.partySize :"-"}</td>
                                      <td>
                                        <select
                                          name="orderStatus"
                                          id="cars"
                                          className="form-select"
                                          value={item.orderStatus}
                                          onChange={(e) =>
                                            handleStatus(e, item._id)
                                          }
                                        >
                                          <option value="pending">
                                            pending
                                          </option>
                                          <option value="confirm">
                                            confirm
                                          </option>
                                          <option value="preparing">
                                            preparing
                                          </option>
                                          <option value="served">served</option>
                                          <option value="completed">
                                            completed
                                          </option>
                                          <option value="prepared">
                                            prepared
                                          </option>
                                          <option value="cancelled">
                                            cancelled
                                          </option>
                                        </select>
                                      </td>
                                      <td>{item.orderType}</td>
                                      {item.paymentStatus ==="pending" ? <td onClick={()=>payAmount(item?._id)}><button type="button" className="btn btn-warning">Pay </button></td>:
                                      <td ><button type="button" className="btn btn-success">Paid </button></td>}
                                      <td>
                                        <div
                                          className="sub-btn"
                                          onClick={()=>handleItemList(item)}
                                        >
                                          See more
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <p>No Orders</p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/*  <TabPanel className="">
                    <section className="upload-recm ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                            <div className="shadowc p-1  bg-hero">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>User Name</th>
                                    <th>Final Amount</th>
                                    <th>Cart Total</th>
                                    <th>Tax & charges</th>
                                    <th>Offer</th>
                                    <th>Discount amount</th>
                                    <th>Table no</th>
                                    <th>Status</th>
                                    <th>Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {diningOrderList.length > 0 ? (
                                    diningOrderList.map((item) => {
                                      return (
                                        <tr>
                                          <td>{item.user.name} </td>
                                          <td> {item.finalAmount}</td>
                                          <td>{item.cartTotal}</td>
                                          <td>{item.taxAndCharges}</td>
                                          <td></td>
                                          <td>{item.discountedAmount}</td>
                                          <td>
                                            {item.tableNo ? item.tableNo : "NA"}
                                          </td>
                                          <td>
                                            <select
                                              name="orderStatus"
                                              id="cars"
                                              className="form-select"
                                              value={item.orderStatus}
                                              onChange={(e) =>
                                                handleStatus(e, item._id)
                                              }
                                            >
                                              <option value="pending">
                                                pending
                                              </option>
                                              <option value="confirm">
                                                confirm
                                              </option>
                                              <option value="preparing">
                                                preparing
                                              </option>
                                              <option value="served">
                                                served
                                              </option>
                                              <option value="completed">
                                                completed
                                              </option>
                                              <option value="prepared">
                                                prepared
                                              </option>
                                              <option value="cancelled">
                                                cancelled
                                              </option>
                                            </select>
                                          </td>
                                          <td>
                                            <a
                                              className="sub-btn"
                                              onClick={handleShow}
                                            >
                                              {" "}
                                              See more
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <p>No Orders</p>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </TabPanel>
                  <TabPanel className="">
                    <section className="upload-recm ">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-12 bg-white p-lg-3 p-2 table-shadow mb-5">
                            <div className="shadowc p-1  bg-hero">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>User Name</th>
                                    <th>Final Amount</th>
                                    <th>Cart Total</th>
                                    <th>Tax & charges</th>
                                    <th>Offer</th>
                                    <th>Discount amount</th>
                                    <th>Status</th>
                                    <th>Items</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {takeAwayOrderList.length > 0 ? (
                                    takeAwayOrderList.map((item) => {
                                      return (
                                        <tr>
                                          <td>{item.user.name} </td>
                                          <td>₹ {item.finalAmount}</td>
                                          <td>₹ {item.cartTotal}</td>
                                          <td>₹ {item.taxAndCharges}</td>
                                          <td></td>
                                          <td>₹ {item.discountedAmount}</td>
                                          <td>
                                            <select
                                              name="orderStatus"
                                              id="cars"
                                              className="form-select"
                                              value={item.orderStatus}
                                              onChange={(e) =>
                                                handleStatus(e, item._id)
                                              }
                                            >
                                              <option value="pending">
                                                pending
                                              </option>
                                              <option value="confirm">
                                                confirm
                                              </option>
                                              <option value="preparing">
                                                preparing
                                              </option>
                                              <option value="served">
                                                served
                                              </option>
                                              <option value="completed">
                                                completed
                                              </option>
                                              <option value="prepared">
                                                prepared
                                              </option>
                                              <option value="cancelled">
                                                cancelled
                                              </option>
                                            </select>
                                          </td>

                                          <td>
                                            <a
                                              className="sub-btn"
                                              onClick={handleShow}
                                            >
                                              {" "}
                                              See more
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <p>No Orders</p>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </TabPanel> */}
              </div>
            </div>
          </section>
        </div>

        {/* items details popup start*/}
      <OrderDetails show={orderDetailPopup} setShow={setOrderDetailPopup} itemList={itemList}/>

        {/* items details end*/}
      </main>
      <CurrentOrder
        show={isInCommingOrder}
        setShow={setIsInCommingOrder}
        data={inCommingOrder}
      />
      <Confirmation
        show={isConfirmation}
        setShow={setIsConfirmation}
        message={confirmationMsg}
        deleteData={hanleOrderStatus}
      />
    </>
  );
};

export default Orders;
