import { React, useEffect, useState } from "react";
import {
  FaArrowAltCircleRight,
  FaChair,
  FaEdit,
  FaTrash,
} from "react-icons/fa";
import { MDBDataTable, MDBInput } from "mdbreact";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { api, baseUrl } from "../url";
import { errorToast, successToast } from "./logics/utils";
import { Http } from "../http-dragon";
import Generate_qrcode from "./generate-qrcode";
import { toast } from "react-toastify";

const Sitting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tableNo, setTableNo] = useState("");
  const [chairs, setChairs] = useState("");
  const [tableList, setTableList] = useState([]);
  const [show, setShow] = useState(false);
  const [tableNoForQrcode, setTableNoForQrcode] = useState(null);

  useEffect(() => {
    if (location?.state?._id) {
      fetchTables();
    } else {
      navigate("/restaurant-lists");
    }
  }, []);

  const openQrCode = (tableNo) => {
    setTableNoForQrcode(tableNo);
    setShow(true);
  };

  const fetchTables = async () => {
    Http.loader(true);
    try {
      const res = await axios.post(
        baseUrl + api.getTableAndChairsOfRestaurant,
        { restaurantID: location?.state?._id }
      );
      Http.loader(false);
      setTableList(res.data?.data);
    } catch (error) {
      Http.loader(false);
      errorToast(error);
    }
    Http.loader(false);
  };

  const saveTables = async () => {
    if(!tableNo){
      toast.error("Tanle No is required..")
      return
    }
    if(!chairs){
      toast.error("Chairs is required..")
      return
    }
    Http.loader(true);
    try {
      const res = await axios.post(
        baseUrl + api.addTableAndChairsOfRestaurant,
        { tableNo, chairs, restaurantID: location?.state?._id }
      );
      successToast(res);
      Http.loader(false);
      setChairs("")
      setTableNo("")
      fetchTables();
    } catch (error) {
      errorToast(error);
      Http.loader(false);
    }
  };
  const deleteTables = async (tableId) => {
    Http.loader(true);
    try {
      const res = await axios.post(baseUrl + api.deleteTables, { tableId });
      successToast(res);
      Http.loader(false);
      fetchTables();
    } catch (error) {
      Http.loader(false);
      errorToast(error);
    }
    Http.loader(false);
  };
  const goSlotsPage = () => {
    navigate("/time-slots", { state: { _id: location.state._id } });
  };
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaChair size="20" />
                        </span>
                      </span>
                      Sitting
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="my-5 sitting">
            <div className="row justify-content-center">
              <div className="col-md-4 shadowc bg-white py-3 rounded">
                <div className="row text-center">
                  <div className="col-5">
                    <label>
                      <b>
                        Table No.<span className="text-danger">*</span>
                      </b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={tableNo}
                      onChange={(e) => setTableNo(e.target.value)}
                    />
                  </div>
                  <div className="col-5">
                    <label>
                      <b>
                        Chair<span className="text-danger">*</span>
                      </b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={chairs}
                      onChange={(e) => setChairs(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-2 align-self-center " onClick={saveTables}>
                <div class="bg-gradient-primary text-white p-2 custom-button  ">
                  Add +
                </div>
              </div>
            </div>
          </section>
          <div className="text-end mb-3 " onClick={goSlotsPage}>
              <div class="sub-btn">
                Add Slots
                <FaArrowAltCircleRight />{" "}
              </div>
            </div>
          <section className="px-4">
            
            <div className="row">
              <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                <table class="table">
                  <thead>
                    <tr>
                      {/* <th scope="col">Sr.No</th> */}
                      <th scope="col">Table No.</th>
                      <th scope="col">Chairs</th>
                      <th scope="col">Generate qrcode</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableList?.length > 0
                      ? tableList.map((item, i) => {
                          return (
                            <tr>
                              {/* <th scope="row">{i + 1}</th> */}
                              <td>{item.tableNo}</td>
                              <td>{item.chairs}</td>
                              <th>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => openQrCode(item.tableNo)}
                                >
                                  gen qrcode
                                </button>
                              </th>
                              <td
                                className="text-danger"
                                onClick={() => deleteTables(item._id)}
                              >
                                <FaTrash />
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Generate_qrcode
        show={show}
        handleClose={() => setShow(false)}
        tableNo={tableNoForQrcode}
        restaurantID={location?.state?._id}
      />
    </>
  );
};
export default Sitting;
