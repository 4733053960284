import { React, useEffect, useState } from 'react';
import { FaUserPlus } from "react-icons/fa";
import { validateField } from '../utils/custom-validations';
import { toast } from 'react-toastify';
import { FaTrash } from "react-icons/fa";
import axios from 'axios';
import { api, baseUrl } from '../url';
import { useLocation, useNavigate } from 'react-router-dom';
import { Http } from '../http-dragon';
import { requestPost, successToast } from '../set-apis';
const Offer = () => {
  const initialValue = {
    offerName: "",
    restaurantID: "",
    offerType: "percentage_discount",
    offerValue: null,
    description: "",
    validFrom: "",
    validTo: "",
    termAndConditions: "",
    conditionValue: null,
  }
  const location = useLocation()
  const navigate = useNavigate()
  const [formData, setFormData] = useState(initialValue)
  const [formError, setFormError] = useState(initialValue)
  const [offerList, setOfferList] = useState([])

  useEffect(() => {
    if (location?.state) {
      console.log(location.state);
      fetchOffers()
      setFormData( { ...formData,restaurantID: location.state?._id})
    } else {
      navigate("/restaurant-lists")
    }
  }, [])

  const fetchOffers = async () => {
    Http.loader(true)
    try {
      const res = await axios.post(baseUrl + api.getOfferByRestaurantId, { restaurantID: location.state?._id })
      Http.loader(false)
      setOfferList(res.data.data)
    } catch (error) {
      setOfferList([])
      Http.loader(false)
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
    if (name == "offerValue" || name == "conditionValue") {
      setFormData({
        ...formData,
        [name]: Number(value)
      });
    }
  };

  const ownerSubmit = async (e) => {
    e.preventDefault()
    console.log("ssssss");
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }

    });

    console.log("dddd",newErrors);
    if (Object.keys(newErrors).length === 0) {
      Http.loader(true)
      try {
        const res = await axios.post(baseUrl + api.createOffer, formData)
        toast.success(res.data.message)
        Http.loader(false)
        setFormData(initialValue)
        fetchOffers()
        // navigate("/restaurant-details",{state:{name:res.data?.data?.name,ownerId:res.data?.data?._id}})
        setFormError(initialValue);
      } catch (error) {
        Http.loader(false)
        toast.error(error.response?.data?.message)
        setFormError(initialValue);
      }
    } else {
      Http.loader(false)
      // toast.error("somethimg went wromg..")
      setFormError(newErrors);
    }
  }
  const deleteOffer = async (offerID) => {
    Http.loader(true)
    let res = await requestPost(api.deleteOfferById, { offerID })
    Http.loader(false)
    if (res.data) {
      toast.success("Delete Successfully")
      fetchOffers()
    } else {

    }
  }
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          < FaUserPlus size='20' />

                        </span>
                      </span>
                      Owner Details
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4">
            <div>

              <form >
                <div className="row  mt-4 shadow-search">
                  <div className="col-12 col-md-4 ">
                    <label htmlFor="applicant"><b>Offer Name</b></label>
                    <input type="text" name="offerName" className="form-control" onChange={handleChange} value={formData.offerName} />
                    <small className='text-danger'>{formError?.offerName}</small>
                  </div>
                  <div className="col-12 col-md-4 mt-lg-0 mt-3">
                    <label htmlFor="applicant"><b>Offer Percentage(%)</b></label>
                    <input type="text" name="offerValue" className="form-control" onChange={handleChange} value={formData.offerValue} />
                    <small className='text-danger'>{formError?.offerValue}</small>
                  </div>
                  <div className="col-12 col-md-4 mt-lg-0 mt-3">
                    <label htmlFor="applicant"><b> Offer Description</b></label>
                    <input type="text" name="description" className="form-control" onChange={handleChange} value={formData.description} />
                    <small className='text-danger'>{formError?.description}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant"><b>Valid From</b></label>
                    <input type="date" name="validFrom" value={formData.validFrom} className="form-control" onChange={handleChange} />
                    <small className='text-danger'>{formError?.validFrom}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant"><b>Valid To</b></label>
                    <input type="date" name="validTo" value={formData.validTo} className="form-control" onChange={handleChange} />
                    <small className='text-danger'>{formError?.validTo}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant"><b>Term And Conditions</b></label>
                    <input type="text" value={formData.termAndConditions} name="termAndConditions" className="form-control" onChange={handleChange} />
                    <small className='text-danger'>{formError?.termAndConditions}</small>
                  </div>
                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant"><b>Offer Apply of Min Value</b></label>
                    <input type="text" name="conditionValue" className="form-control" onChange={handleChange} />
                    <small className='text-danger'>{formError?.conditionValue}</small>
                  </div>
                  <div className="col-12 col-md-4 align-self-center mt-4 pt-2 text-center">
                    <button type='button' onClick={ownerSubmit} className="bg-gradient-primary text-white p-2 custom-button padding-btn ">{location?.state?.id ? "Update" : "Submit"}</button>
                  </div>
                </div>
              </form>
            </div>
          </section>
          <section className="px-4">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Offer Name</th>
                    <th>Offer Value</th>
                    <th>Valid From</th>
                    <th>Valid To</th>
                    <th>Description</th>
                    <th>Tern & Conditions</th>
                  </tr>
                  {offerList.map((el, i) => {
                    return (
                      <tr key={el.offerName}>
                        <th>{i + 1}</th>
                        <th>{el.offerName}</th>
                        <td>
                          {el.offerValue}
                        </td>
                        <td>{new Date(el.validFrom).toISOString().split('T')[0]}</td>
                        <td>{new Date(el.validTo).toISOString().split('T')[0]}</td>
                        <td>{el.description}</td>
                        <td>{el.termAndConditions}</td>
                        <td className="text-danger" onClick={() => deleteOffer(el._id)}><FaTrash size={20} className="p-0" /></td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
export default Offer;